<template>
    <v-row no-gutters class="my-10" justify="center" align="center">
        <v-col v-if="$vuetify.breakpoint.mobile" cols="12" class="my-0">
            <img
                :src="require('@/assets/grafiti-crown.png')"
                height="100px"
            >
            <img
                :src="require('@/assets/MVP-TAG.png')"
                height="100px"
            >
        </v-col>
        <v-col cols="12" md="5" align="center" class="mb-5">
            <div v-if="!$vuetify.breakpoint.mobile">
                <img
                    :src="require('@/assets/grafiti-crown.png')"
                    height="100px"
                >
                <img
                    :src="require('@/assets/MVP-TAG.png')"
                    height="100px"
                >
            </div>
            <img
                v-if="player.image"
                :height="$vuetify.breakpoint.mobile ? '200px' : '350px'"
                :aspect-ratio="16/9"
                :src="`${assetsUrl}/${player.image}`"
                class="clickable"
                contain
                @click="$router.push({path:`/Player/${player.id}`})"
                @error="player.image = null"
            >
            <img
                v-else
                :height="$vuetify.breakpoint.mobile ? '200px' : '350px'"
                :aspect-ratio="16/9"
                :src="require('@/assets/main_logo.png')"
                contain
            >
            <div class="mvp-text font-weight-bold custom-orange-text">
                {{ `${player.name} ${player.surname}` }}
            </div>
        </v-col>
        <v-col cols="12" md="7" align="center">
            <v-row class="pb-5">
                <v-col
                    v-for="(stat, index) in stats"
                    :key="index"
                    cols="4"
                    md="4"
                    class=" mx-0 px-5"
                    style="text-transform: capitalize;"
                    align="center"
                >
                    <v-card v-if="!$vuetify.breakpoint.mobile" class="elevation-1 rounded-lg">
                        <div class="custom-orange white--text text-h4 secondary-font font-weight-bold py-16">
                            {{ player.stats[0][stat.value] }}
                        </div>
                        <div class="text-h5 font-weight-bold py-8">
                            {{ stat.text }}
                        </div>
                    </v-card>
                    <div v-else class="circle-border">
                        <div class="secondary-font text-h6">
                            {{ player.stats[0][stat.value] }}
                        </div>
                        <div class="custom-orange-text">
                            {{ stat.text }}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            player: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                stats: [
                    { text: 'Pts', value: 'points' },
                    { text: 'TR', value: 'rebounds' },
                    { text: 'AST', value: 'assists' },
                    { text: 'STL', value: 'steals' },
                    { text: 'TO', value: 'turnovers' },
                    { text: 'BLK', value: 'blockedShots' }
                ],
                assetsUrl: 'https://storage.googleapis.com'
            }
        }
    }
</script>

<style scoped>
.circle-border {
    border-radius: 50%;
    border: 3px solid orange;
    width: 90px;
    height: 90px;
    padding: 15px;
    background: #fff;
    text-align: center;
}

.text-h5 {
    font-size: 1.5rem !important;
    font-weight: 400;
    letter-spacing: normal !important;
    font-family: Mansalva, Arial, sans-serif !important;
}

.mvp-text {
    font-size: 2rem !important;
    letter-spacing: normal !important;
    font-family: Mansalva, Arial, sans-serif !important;
}

.text-h4 {
    font-size: 2.125rem !important;
    line-height: 2.5rem;
    letter-spacing: 0.0073529412em !important;
}

.top-arc {
    border-width: 2px;
    border-radius: 10px 5% / 20px 25em 30px 35em;
    border-color: orange;
}
</style>
