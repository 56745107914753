<template>
    <v-container fluid :class="$vuetify.breakpoint.mobile ? 'mt-3' : 'mt-6'">
        <match-banner :match="match" />
        <v-row class="mb-5">
            <v-col cols="12" class="mx-0 px-0">
                <v-tabs
                    v-model="tab"
                    centered
                >
                    <v-tabs-slider class="custom-orange" />
                    <v-tab v-for="(item, index) in match.videoUrl ? items.concat([{ name: 'Βίντεο Παιχνιδιού' }]) : items" :key="index">{{ item.name }}</v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row v-if="this.match.pdfUrl">
            <v-col cols="12">
                <v-btn style="text-transform: capitalize;" class="secondary-font" @click="downloadStats">
                    <v-icon large class="custom-orange-text mr-2">
                        mdi-cloud-download
                    </v-icon>
                    <span>Στατιστικά Αγώνα</span>
                </v-btn>
            </v-col>
        </v-row>
        <main-match-stats-info v-if="tab === 0" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :match="match" />
        <main-match-stats-player v-else-if="tab === 1" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" :match="match" />
        <v-row v-else-if="tab === 2 && match.videoUrl" class="my-5" :class="$vuetify.breakpoint.mobile ? '' : 'px-16'" justify="center">
            <v-col cols="12" md="10">
                <v-card class="rounded-xl elevation-5 py-0 my-0" :height="$vuetify.breakpoint.mobile ? '200px' : '700px'">
                    <iframe
                        class="iframe-container"
                        width="100%"
                        height="100%"
                        :src="match.videoUrl"
                        title="Youtube Video"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <main-match-stats-info class="mb-5" :match="match" :mvp="false" />
            </v-col>
            <v-col cols="12" md="6">
                <main-match-stats-player :match="match" :mvp="false" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MatchBanner from '@/components/MatchBanner'
    import MainMatchStatsPlayer from '@/components/MainMatchStatsPlayer'
    import MainMatchStatsInfo from '@/components/MainMatchStatsInfo'
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'
    import moment from 'moment'

    export default {
        components: { MatchBanner, MainMatchStatsPlayer, MainMatchStatsInfo },
        data() {
            return {
                tab: null,
                polling: null,
                items: [
                    {
                        name: 'Στατιστικά Παιχνιδιού'
                    },
                    {
                        name: 'Στατιστικά Παιχτών'
                    }
                ],
                match: {},
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        beforeDestroy() {
            clearInterval(this.polling)
        },
        created() {
            this.fetchMatch(this.$route.params.id).then(() => {
                const live = momentTimezone().tz('Europe/Athens').add(3, 'hours').locale('el-GR').format('dddd D/M/y, HH:mm')
                const now = momentTimezone().tz('Europe/Athens').locale('el-GR').format('dddd D/M/y, HH:mm')
                if (moment().utc(now).isSameOrBefore(moment().utc(live), 'hour')) {
                    setTimeout(() => {
                        this.polling = setInterval(() => {
                            this.fetchMatch(this.$route.params.id)
                        }, 3000)
                    }, 3000)
                } else {
                    this.fetchMatch(this.$route.params.id)
                }
            })
        },
        methods: {
            fetchMatch(matchId) {
                return matchService.getMatch(matchId).then(resp => {
                    this.match = resp.data
                })
            },
            downloadStats() {
                window.open(`${this.assetsUrl}/${this.match.pdfUrl}`, '_blank')
            }
        }
    }
</script>

<style scoped>

.mobile-sticky-banner {
    position: sticky;
    top: 80px;
    z-index: 1;
}

.pc-sticky-banner {
    position: sticky;
    top: 60px;
    z-index: 1;
}

.v-tab {
    text-transform: none !important;
    font-weight: bolder;
    font-size: 15px;
    color: #757578 !important;
}

.v-tab::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}

.v-tab--active {
    color: orange !important;
}
</style>
